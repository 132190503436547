<template>
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">限时活动列表</div>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1000 }"
      >
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].status"
            :text="statuText[record.status].text"
          />
        </template>
        <template slot="startAt" slot-scope="record">
          <span v-if="record.startAt">{{ record.startAt }}</span>
          <span v-else>-</span>
        </template>
        <template slot="endAt" slot-scope="record">
          <span v-if="record.endAt">{{ record.endAt }}</span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link">
            <router-link
              :to="{
                path: '/activityInfo',
                query: { id: record.id }
              }"
            >
              活动详情
            </router-link>
          </a-button>
          <a-button
            type="link"
            @click="onChangeStatus({ id: record.id, status: record.status })"
          >
            <span v-if="record.status === 0">上线</span>
            <span v-else>下线</span>
          </a-button>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '活动名称',
          width: 200,
          dataIndex: 'name'
        },
        {
          title: '活动状态',
          width: 100,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '预计开始时间',
          width: 200,
          scopedSlots: { customRender: 'startAt' }
        },
        {
          title: '预计关闭时间',
          scopedSlots: { customRender: 'endAt' }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      statuText: [
        {
          status: 'default',
          text: '关闭'
        },
        {
          status: 'success',
          text: '开启'
        }
      ]
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getActivity()
  },
  methods: {
    getActivity() {
      this.$axios.getActivity().then((res) => {
        this.dataSource = res.data.data.data
        this.loading = false
      })
    },
    onChangeStatus(e) {
      let title = ''
      let status = ''
      if (e.status === 1) {
        title = '确定下线？'
        status = 0
      } else {
        title = '确定上线？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e.id,
            status: status
          }
          this.$axios.changeActivityStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getActivity()
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>
